/*! Normalize CSS */

img,
legend {
  border: 0;
}

legend,
td,
th {
  padding: 0;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
optgroup,
strong {
  font-weight: 700;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre,
textarea {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  outline: 0;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  max-width: 100%;
  height: auto;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*! Bootstrap Grid */

.container,
.container-fluid {
  margin-right: auto;
  margin-left: auto;
}

.container-fluid::after,
.container::after,
.row::after {
  display: table;
  clear: both;
  content: "";
}

.container,
.container-fluid {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

*,
::after,
::before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

@-ms-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

@media (min-width: 544px) {
  .container {
    max-width: 576px;
  }
}

.row {
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left;
}

.col-xs-1 {
  width: 8.333333%;
}

.col-xs-2 {
  width: 16.666667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.333333%;
}

.col-xs-5 {
  width: 41.666667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.333333%;
}

.col-xs-8 {
  width: 66.666667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.333333%;
}

.col-xs-11 {
  width: 91.666667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.333333%;
}

.col-xs-pull-2 {
  right: 16.666667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.333333%;
}

.col-xs-pull-5 {
  right: 41.666667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.333333%;
}

.col-xs-pull-8 {
  right: 66.666667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.333333%;
}

.col-xs-pull-11 {
  right: 91.666667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.333333%;
}

.col-xs-push-2 {
  left: 16.666667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.333333%;
}

.col-xs-push-5 {
  left: 41.666667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.333333%;
}

.col-xs-push-8 {
  left: 66.666667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.333333%;
}

.col-xs-push-11 {
  left: 91.666667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

.col-xs-offset-1 {
  margin-left: 8.333333%;
}

.col-xs-offset-2 {
  margin-left: 16.666667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.333333%;
}

.col-xs-offset-5 {
  margin-left: 41.666667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.333333%;
}

.col-xs-offset-8 {
  margin-left: 66.666667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.333333%;
}

.col-xs-offset-11 {
  margin-left: 91.666667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 480px) {
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9 {
    float: left;
  }
  .col-sm-1 {
    width: 8.333333%;
  }
  .col-sm-2 {
    width: 16.666667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.333333%;
  }
  .col-sm-5 {
    width: 41.666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.333333%;
  }
  .col-sm-8 {
    width: 66.666667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.333333%;
  }
  .col-sm-11 {
    width: 91.666667%;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-pull-1 {
    right: 8.333333%;
  }
  .col-sm-pull-2 {
    right: 16.666667%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-4 {
    right: 33.333333%;
  }
  .col-sm-pull-5 {
    right: 41.666667%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-7 {
    right: 58.333333%;
  }
  .col-sm-pull-8 {
    right: 66.666667%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-10 {
    right: 83.333333%;
  }
  .col-sm-pull-11 {
    right: 91.666667%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-push-1 {
    left: 8.333333%;
  }
  .col-sm-push-2 {
    left: 16.666667%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-4 {
    left: 33.333333%;
  }
  .col-sm-push-5 {
    left: 41.666667%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-7 {
    left: 58.333333%;
  }
  .col-sm-push-8 {
    left: 66.666667%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-10 {
    left: 83.333333%;
  }
  .col-sm-push-11 {
    left: 91.666667%;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }
  .col-sm-offset-1 {
    margin-left: 8.333333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.666667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.333333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.666667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.333333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.666667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.333333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.666667%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9 {
    float: left;
  }
  .col-md-1 {
    width: 8.333333%;
  }
  .col-md-2 {
    width: 16.666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.333333%;
  }
  .col-md-5 {
    width: 41.666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.333333%;
  }
  .col-md-8 {
    width: 66.666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.333333%;
  }
  .col-md-11 {
    width: 91.666667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-pull-1 {
    right: 8.333333%;
  }
  .col-md-pull-2 {
    right: 16.666667%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-4 {
    right: 33.333333%;
  }
  .col-md-pull-5 {
    right: 41.666667%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-7 {
    right: 58.333333%;
  }
  .col-md-pull-8 {
    right: 66.666667%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-10 {
    right: 83.333333%;
  }
  .col-md-pull-11 {
    right: 91.666667%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-push-1 {
    left: 8.333333%;
  }
  .col-md-push-2 {
    left: 16.666667%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-4 {
    left: 33.333333%;
  }
  .col-md-push-5 {
    left: 41.666667%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-7 {
    left: 58.333333%;
  }
  .col-md-push-8 {
    left: 66.666667%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-10 {
    left: 83.333333%;
  }
  .col-md-push-11 {
    left: 91.666667%;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0;
  }
  .col-md-offset-1 {
    margin-left: 8.333333%;
  }
  .col-md-offset-2 {
    margin-left: 16.666667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.333333%;
  }
  .col-md-offset-5 {
    margin-left: 41.666667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.333333%;
  }
  .col-md-offset-8 {
    margin-left: 66.666667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.333333%;
  }
  .col-md-offset-11 {
    margin-left: 91.666667%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 940px;
  }
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9 {
    float: left;
  }
  .col-lg-1 {
    width: 8.333333%;
  }
  .col-lg-2 {
    width: 16.666667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.333333%;
  }
  .col-lg-5 {
    width: 41.666667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.333333%;
  }
  .col-lg-8 {
    width: 66.666667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.333333%;
  }
  .col-lg-11 {
    width: 91.666667%;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-pull-1 {
    right: 8.333333%;
  }
  .col-lg-pull-2 {
    right: 16.666667%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-4 {
    right: 33.333333%;
  }
  .col-lg-pull-5 {
    right: 41.666667%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-7 {
    right: 58.333333%;
  }
  .col-lg-pull-8 {
    right: 66.666667%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-10 {
    right: 83.333333%;
  }
  .col-lg-pull-11 {
    right: 91.666667%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-push-1 {
    left: 8.333333%;
  }
  .col-lg-push-2 {
    left: 16.666667%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-4 {
    left: 33.333333%;
  }
  .col-lg-push-5 {
    left: 41.666667%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-7 {
    left: 58.333333%;
  }
  .col-lg-push-8 {
    left: 66.666667%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-10 {
    left: 83.333333%;
  }
  .col-lg-push-11 {
    left: 91.666667%;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
  .col-lg-offset-1 {
    margin-left: 8.333333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.666667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.333333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.666667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.333333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.666667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.333333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.666667%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9 {
    float: left;
  }
  .col-xl-1 {
    width: 8.333333%;
  }
  .col-xl-2 {
    width: 16.666667%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-4 {
    width: 33.333333%;
  }
  .col-xl-5 {
    width: 41.666667%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-7 {
    width: 58.333333%;
  }
  .col-xl-8 {
    width: 66.666667%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-10 {
    width: 83.333333%;
  }
  .col-xl-11 {
    width: 91.666667%;
  }
  .col-xl-12 {
    width: 100%;
  }
  .col-xl-pull-0 {
    right: auto;
  }
  .col-xl-pull-1 {
    right: 8.333333%;
  }
  .col-xl-pull-2 {
    right: 16.666667%;
  }
  .col-xl-pull-3 {
    right: 25%;
  }
  .col-xl-pull-4 {
    right: 33.333333%;
  }
  .col-xl-pull-5 {
    right: 41.666667%;
  }
  .col-xl-pull-6 {
    right: 50%;
  }
  .col-xl-pull-7 {
    right: 58.333333%;
  }
  .col-xl-pull-8 {
    right: 66.666667%;
  }
  .col-xl-pull-9 {
    right: 75%;
  }
  .col-xl-pull-10 {
    right: 83.333333%;
  }
  .col-xl-pull-11 {
    right: 91.666667%;
  }
  .col-xl-pull-12 {
    right: 100%;
  }
  .col-xl-push-0 {
    left: auto;
  }
  .col-xl-push-1 {
    left: 8.333333%;
  }
  .col-xl-push-2 {
    left: 16.666667%;
  }
  .col-xl-push-3 {
    left: 25%;
  }
  .col-xl-push-4 {
    left: 33.333333%;
  }
  .col-xl-push-5 {
    left: 41.666667%;
  }
  .col-xl-push-6 {
    left: 50%;
  }
  .col-xl-push-7 {
    left: 58.333333%;
  }
  .col-xl-push-8 {
    left: 66.666667%;
  }
  .col-xl-push-9 {
    left: 75%;
  }
  .col-xl-push-10 {
    left: 83.333333%;
  }
  .col-xl-push-11 {
    left: 91.666667%;
  }
  .col-xl-push-12 {
    left: 100%;
  }
  .col-xl-offset-0 {
    margin-left: 0;
  }
  .col-xl-offset-1 {
    margin-left: 8.333333%;
  }
  .col-xl-offset-2 {
    margin-left: 16.666667%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-4 {
    margin-left: 33.333333%;
  }
  .col-xl-offset-5 {
    margin-left: 41.666667%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-7 {
    margin-left: 58.333333%;
  }
  .col-xl-offset-8 {
    margin-left: 66.666667%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-10 {
    margin-left: 83.333333%;
  }
  .col-xl-offset-11 {
    margin-left: 91.666667%;
  }
  .col-xl-offset-12 {
    margin-left: 100%;
  }
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.row:after,
.row:before {
  content: " ";
  display: table;
}

.clearfix:after,
.container-fluid:after,
.container:after,
.row:after {
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

/*! Icons */

@charset "UTF-8";
@font-face {
  font-family: "linea-basic-10";

  src: url(../icon-fonts/linea-basic-10.woff) format("woff");

  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "linea-software-10";
  src: url(../icon-fonts/linea-basic-10.woff);
  font-weight: 400;
  font-style: normal;
}

[class^="icon-basic"]:before,
[class*=" icon-basic"]:before {
  font-family: "linea-basic-10" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-software"]:before,
[class*=" icon-software"]:before {
  font-family: "linea-software-10" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-basic-accelerator:before {
  content: "a";
}

.icon-basic-alarm:before {
  content: "b";
}

.icon-basic-anchor:before {
  content: "c";
}

.icon-basic-anticlockwise:before {
  content: "d";
}

.icon-basic-archive:before {
  content: "e";
}

.icon-basic-archive-full:before {
  content: "f";
}

.icon-basic-ban:before {
  content: "g";
}

.icon-basic-battery-charge:before {
  content: "h";
}

.icon-basic-battery-empty:before {
  content: "i";
}

.icon-basic-battery-full:before {
  content: "j";
}

.icon-basic-battery-half:before {
  content: "k";
}

.icon-basic-bolt:before {
  content: "l";
}

.icon-basic-book:before {
  content: "m";
}

.icon-basic-book-pen:before {
  content: "n";
}

.icon-basic-book-pencil:before {
  content: "o";
}

.icon-basic-bookmark:before {
  content: "p";
}

.icon-basic-calculator:before {
  content: "q";
}

.icon-basic-calendar:before {
  content: "r";
}

.icon-basic-cards-diamonds:before {
  content: "s";
}

.icon-basic-cards-hearts:before {
  content: "t";
}

.icon-basic-case:before {
  content: "u";
}

.icon-basic-chronometer:before {
  content: "v";
}

.icon-basic-clessidre:before {
  content: "w";
}

.icon-basic-clock:before {
  content: "x";
}

.icon-basic-clockwise:before {
  content: "y";
}

.icon-basic-cloud:before {
  content: "z";
}

.icon-basic-clubs:before {
  content: "A";
}

.icon-basic-compass:before {
  content: "B";
}

.icon-basic-cup:before {
  content: "C";
}

.icon-basic-diamonds:before {
  content: "D";
}

.icon-basic-display:before {
  content: "E";
}

.icon-basic-download:before {
  content: "F";
}

.icon-basic-exclamation:before {
  content: "G";
}

.icon-basic-eye:before {
  content: "H";
}

.icon-basic-eye-closed:before {
  content: "I";
}

.icon-basic-female:before {
  content: "J";
}

.icon-basic-flag1:before {
  content: "K";
}

.icon-basic-flag2:before {
  content: "L";
}

.icon-basic-floppydisk:before {
  content: "M";
}

.icon-basic-folder:before {
  content: "N";
}

.icon-basic-folder-multiple:before {
  content: "O";
}

.icon-basic-gear:before {
  content: "P";
}

.icon-basic-geolocalize-01:before {
  content: "Q";
}

.icon-basic-geolocalize-05:before {
  content: "R";
}

.icon-basic-globe:before {
  content: "S";
}

.icon-basic-gunsight:before {
  content: "T";
}

.icon-basic-hammer:before {
  content: "U";
}

.icon-basic-headset:before {
  content: "V";
}

.icon-basic-heart:before {
  content: "W";
}

.icon-basic-heart-broken:before {
  content: "X";
}

.icon-basic-helm:before {
  content: "Y";
}

.icon-basic-home:before {
  content: "Z";
}

.icon-basic-info:before {
  content: "0";
}

.icon-basic-ipod:before {
  content: "1";
}

.icon-basic-joypad:before {
  content: "2";
}

.icon-basic-key:before {
  content: "3";
}

.icon-basic-keyboard:before {
  content: "4";
}

.icon-basic-laptop:before {
  content: "5";
}

.icon-basic-life-buoy:before {
  content: "6";
}

.icon-basic-lightbulb:before {
  content: "7";
}

.icon-basic-link:before {
  content: "8";
}

.icon-basic-lock:before {
  content: "9";
}

.icon-basic-lock-open:before {
  content: "!";
}

.icon-basic-magic-mouse:before {
  content: '"';
}

.icon-basic-magnifier:before {
  content: "#";
}

.icon-basic-magnifier-minus:before {
  content: "$";
}

.icon-basic-magnifier-plus:before {
  content: "%";
}

.icon-basic-mail:before {
  content: "&";
}

.icon-basic-mail-multiple:before {
  content: "'";
}

.icon-basic-mail-open:before {
  content: "(";
}

.icon-basic-mail-open-text:before {
  content: ")";
}

.icon-basic-male:before {
  content: "*";
}

.icon-basic-map:before {
  content: "+";
}

.icon-basic-message:before {
  content: ",";
}

.icon-basic-message-multiple:before {
  content: "-";
}

.icon-basic-message-txt:before {
  content: ".";
}

.icon-basic-mixer2:before {
  content: "/";
}

.icon-basic-mouse:before {
  content: ":";
}

.icon-basic-notebook:before {
  content: ";";
}

.icon-basic-notebook-pen:before {
  content: "<";
}

.icon-basic-notebook-pencil:before {
  content: "=";
}

.icon-basic-paperplane:before {
  content: ">";
}

.icon-basic-pencil-ruler:before {
  content: "?";
}

.icon-basic-pencil-ruler-pen:before {
  content: "@";
}

.icon-basic-photo:before {
  content: "[";
}

.icon-basic-picture:before {
  content: "]";
}

.icon-basic-picture-multiple:before {
  content: "^";
}

.icon-basic-pin1:before {
  content: "_";
}

.icon-basic-pin2:before {
  content: "`";
}

.icon-basic-postcard:before {
  content: "{";
}

.icon-basic-postcard-multiple:before {
  content: "|";
}

.icon-basic-printer:before {
  content: "}";
}

.icon-basic-question:before {
  content: "~";
}

.icon-basic-rss:before {
  content: "\\";
}

.icon-basic-server:before {
  content: "\e000";
}

.icon-basic-server2:before {
  content: "\e001";
}

.icon-basic-server-cloud:before {
  content: "\e002";
}

.icon-basic-server-download:before {
  content: "\e003";
}

.icon-basic-server-upload:before {
  content: "\e004";
}

.icon-basic-settings:before {
  content: "\e005";
}

.icon-basic-share:before {
  content: "\e006";
}

.icon-basic-sheet:before {
  content: "\e007";
}

.icon-basic-sheet-multiple:before {
  content: "\e008";
}

.icon-basic-sheet-pen:before {
  content: "\e009";
}

.icon-basic-sheet-pencil:before {
  content: "\e00a";
}

.icon-basic-sheet-txt:before {
  content: "\e00b";
}

.icon-basic-signs:before {
  content: "\e00c";
}

.icon-basic-smartphone:before {
  content: "\e00d";
}

.icon-basic-spades:before {
  content: "\e00e";
}

.icon-basic-spread:before {
  content: "\e00f";
}

.icon-basic-spread-bookmark:before {
  content: "\e010";
}

.icon-basic-spread-text:before {
  content: "\e011";
}

.icon-basic-spread-text-bookmark:before {
  content: "\e012";
}

.icon-basic-star:before {
  content: "\e013";
}

.icon-basic-tablet:before {
  content: "\e014";
}

.icon-basic-target:before {
  content: "\e015";
}

.icon-basic-todo:before {
  content: "\e016";
}

.icon-basic-todo-pen:before {
  content: "\e017";
}

.icon-basic-todo-pencil:before {
  content: "\e018";
}

.icon-basic-todo-txt:before {
  content: "\e019";
}

.icon-basic-todolist-pen:before {
  content: "\e01a";
}

.icon-basic-todolist-pencil:before {
  content: "\e01b";
}

.icon-basic-trashcan:before {
  content: "\e01c";
}

.icon-basic-trashcan-full:before {
  content: "\e01d";
}

.icon-basic-trashcan-refresh:before {
  content: "\e01e";
}

.icon-basic-trashcan-remove:before {
  content: "\e01f";
}

.icon-basic-upload:before {
  content: "\e020";
}

.icon-basic-usb:before {
  content: "\e021";
}

.icon-basic-video:before {
  content: "\e022";
}

.icon-basic-watch:before {
  content: "\e023";
}

.icon-basic-webpage:before {
  content: "\e024";
}

.icon-basic-webpage-img-txt:before {
  content: "\e025";
}

.icon-basic-webpage-multiple:before {
  content: "\e026";
}

.icon-basic-webpage-txt:before {
  content: "\e027";
}

.icon-basic-world:before {
  content: "\e028";
}

.icon-software-add-vectorpoint:before {
  content: "a";
}

.icon-software-box-oval:before {
  content: "b";
}

.icon-software-box-polygon:before {
  content: "c";
}

.icon-software-box-rectangle:before {
  content: "d";
}

.icon-software-box-roundedrectangle:before {
  content: "e";
}

.icon-software-character:before {
  content: "f";
}

.icon-software-crop:before {
  content: "g";
}

.icon-software-eyedropper:before {
  content: "h";
}

.icon-software-font-allcaps:before {
  content: "i";
}

.icon-software-font-baseline-shift:before {
  content: "j";
}

.icon-software-font-horizontal-scale:before {
  content: "k";
}

.icon-software-font-kerning:before {
  content: "l";
}

.icon-software-font-leading:before {
  content: "m";
}

.icon-software-font-size:before {
  content: "n";
}

.icon-software-font-smallcapital:before {
  content: "o";
}

.icon-software-font-smallcaps:before {
  content: "p";
}

.icon-software-font-strikethrough:before {
  content: "q";
}

.icon-software-font-tracking:before {
  content: "r";
}

.icon-software-font-underline:before {
  content: "s";
}

.icon-software-font-vertical-scale:before {
  content: "t";
}

.icon-software-horizontal-align-center:before {
  content: "u";
}

.icon-software-horizontal-align-left:before {
  content: "v";
}

.icon-software-horizontal-align-right:before {
  content: "w";
}

.icon-software-horizontal-distribute-center:before {
  content: "x";
}

.icon-software-horizontal-distribute-left:before {
  content: "y";
}

.icon-software-horizontal-distribute-right:before {
  content: "z";
}

.icon-software-indent-firstline:before {
  content: "A";
}

.icon-software-indent-left:before {
  content: "B";
}

.icon-software-indent-right:before {
  content: "C";
}

.icon-software-lasso:before {
  content: "D";
}

.icon-software-layers1:before {
  content: "E";
}

.icon-software-layers2:before {
  content: "F";
}

.icon-software-layout:before {
  content: "G";
}

.icon-software-layout-2columns:before {
  content: "H";
}

.icon-software-layout-3columns:before {
  content: "I";
}

.icon-software-layout-4boxes:before {
  content: "J";
}

.icon-software-layout-4columns:before {
  content: "K";
}

.icon-software-layout-4lines:before {
  content: "L";
}

.icon-software-layout-8boxes:before {
  content: "M";
}

.icon-software-layout-header:before {
  content: "N";
}

.icon-software-layout-header-2columns:before {
  content: "O";
}

.icon-software-layout-header-3columns:before {
  content: "P";
}

.icon-software-layout-header-4boxes:before {
  content: "Q";
}

.icon-software-layout-header-4columns:before {
  content: "R";
}

.icon-software-layout-header-complex:before {
  content: "S";
}

.icon-software-layout-header-complex2:before {
  content: "T";
}

.icon-software-layout-header-complex3:before {
  content: "U";
}

.icon-software-layout-header-complex4:before {
  content: "V";
}

.icon-software-layout-header-sideleft:before {
  content: "W";
}

.icon-software-layout-header-sideright:before {
  content: "X";
}

.icon-software-layout-sidebar-left:before {
  content: "Y";
}

.icon-software-layout-sidebar-right:before {
  content: "Z";
}

.icon-software-magnete:before {
  content: "0";
}

.icon-software-pages:before {
  content: "1";
}

.icon-software-paintbrush:before {
  content: "2";
}

.icon-software-paintbucket:before {
  content: "3";
}

.icon-software-paintroller:before {
  content: "4";
}

.icon-software-paragraph:before {
  content: "5";
}

.icon-software-paragraph-align-left:before {
  content: "6";
}

.icon-software-paragraph-align-right:before {
  content: "7";
}

.icon-software-paragraph-center:before {
  content: "8";
}

.icon-software-paragraph-justify-all:before {
  content: "9";
}

.icon-software-paragraph-justify-center:before {
  content: "!";
}

.icon-software-paragraph-justify-left:before {
  content: '"';
}

.icon-software-paragraph-justify-right:before {
  content: "#";
}

.icon-software-paragraph-space-after:before {
  content: "$";
}

.icon-software-paragraph-space-before:before {
  content: "%";
}

.icon-software-pathfinder-exclude:before {
  content: "&";
}

.icon-software-pathfinder-intersect:before {
  content: "'";
}

.icon-software-pathfinder-subtract:before {
  content: "(";
}

.icon-software-pathfinder-unite:before {
  content: ")";
}

.icon-software-pen:before {
  content: "*";
}

.icon-software-pen-add:before {
  content: "+";
}

.icon-software-pen-remove:before {
  content: ",";
}

.icon-software-pencil:before {
  content: "-";
}

.icon-software-polygonallasso:before {
  content: ".";
}

.icon-software-reflect-horizontal:before {
  content: "/";
}

.icon-software-reflect-vertical:before {
  content: ":";
}

.icon-software-remove-vectorpoint:before {
  content: ";";
}

.icon-software-scale-expand:before {
  content: "<";
}

.icon-software-scale-reduce:before {
  content: "=";
}

.icon-software-selection-oval:before {
  content: ">";
}

.icon-software-selection-polygon:before {
  content: "?";
}

.icon-software-selection-rectangle:before {
  content: "@";
}

.icon-software-selection-roundedrectangle:before {
  content: "[";
}

.icon-software-shape-oval:before {
  content: "]";
}

.icon-software-shape-polygon:before {
  content: "^";
}

.icon-software-shape-rectangle:before {
  content: "_";
}

.icon-software-shape-roundedrectangle:before {
  content: "`";
}

.icon-software-slice:before {
  content: "{";
}

.icon-software-transform-bezier:before {
  content: "|";
}

.icon-software-vector-box:before {
  content: "}";
}

.icon-software-vector-composite:before {
  content: "~";
}

.icon-software-vector-line:before {
  content: "\\";
}

.icon-software-vertical-align-bottom:before {
  content: "\e000";
}

.icon-software-vertical-align-center:before {
  content: "\e001";
}

.icon-software-vertical-align-top:before {
  content: "\e002";
}

.icon-software-vertical-distribute-bottom:before {
  content: "\e003";
}

.icon-software-vertical-distribute-center:before {
  content: "\e004";
}

.icon-software-vertical-distribute-top:before {
  content: "\e005";
}

/*! Social Icons */

@font-face {
  font-family: socicon;
  src: url(../icon-fonts/socicon.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

[class*=" socicon-"]:before,
[class^="socicon-"]:before {
  font-family: socicon !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.socicon-modelmayhem:before {
  content: "\e000";
}

.socicon-mixcloud:before {
  content: "\e001";
}

.socicon-drupal:before {
  content: "\e002";
}

.socicon-swarm:before {
  content: "\e003";
}

.socicon-istock:before {
  content: "\e004";
}

.socicon-yammer:before {
  content: "\e005";
}

.socicon-ello:before {
  content: "\e006";
}

.socicon-stackoverflow:before {
  content: "\e007";
}

.socicon-persona:before {
  content: "\e008";
}

.socicon-triplej:before {
  content: "\e009";
}

.socicon-houzz:before {
  content: "\e00a";
}

.socicon-rss:before {
  content: "\e00b";
}

.socicon-paypal:before {
  content: "\e00c";
}

.socicon-odnoklassniki:before {
  content: "\e00d";
}

.socicon-airbnb:before {
  content: "\e00e";
}

.socicon-periscope:before {
  content: "\e00f";
}

.socicon-outlook:before {
  content: "\e010";
}

.socicon-coderwall:before {
  content: "\e011";
}

.socicon-tripadvisor:before {
  content: "\e012";
}

.socicon-appnet:before {
  content: "\e013";
}

.socicon-goodreads:before {
  content: "\e014";
}

.socicon-tripit:before {
  content: "\e015";
}

.socicon-lanyrd:before {
  content: "\e016";
}

.socicon-slideshare:before {
  content: "\e017";
}

.socicon-buffer:before {
  content: "\e018";
}

.socicon-disqus:before {
  content: "\e019";
}

.socicon-vkontakte:before {
  content: "\e01a";
}

.socicon-whatsapp:before {
  content: "\e01b";
}

.socicon-patreon:before {
  content: "\e01c";
}

.socicon-storehouse:before {
  content: "\e01d";
}

.socicon-pocket:before {
  content: "\e01e";
}

.socicon-mail:before {
  content: "\e01f";
}

.socicon-blogger:before {
  content: "\e020";
}

.socicon-technorati:before {
  content: "\e021";
}

.socicon-reddit:before {
  content: "\e022";
}

.socicon-dribbble:before {
  content: "\e023";
}

.socicon-stumbleupon:before {
  content: "\e024";
}

.socicon-digg:before {
  content: "\e025";
}

.socicon-envato:before {
  content: "\e026";
}

.socicon-behance:before {
  content: "\e027";
}

.socicon-delicious:before {
  content: "\e028";
}

.socicon-deviantart:before {
  content: "\e029";
}

.socicon-forrst:before {
  content: "\e02a";
}

.socicon-play:before {
  content: "\e02b";
}

.socicon-zerply:before {
  content: "\e02c";
}

.socicon-wikipedia:before {
  content: "\e02d";
}

.socicon-apple:before {
  content: "\e02e";
}

.socicon-flattr:before {
  content: "\e02f";
}

.socicon-github:before {
  content: "\e030";
}

.socicon-renren:before {
  content: "\e031";
}

.socicon-friendfeed:before {
  content: "\e032";
}

.socicon-newsvine:before {
  content: "\e033";
}

.socicon-identica:before {
  content: "\e034";
}

.socicon-bebo:before {
  content: "\e035";
}

.socicon-zynga:before {
  content: "\e036";
}

.socicon-steam:before {
  content: "\e037";
}

.socicon-xbox:before {
  content: "\e038";
}

.socicon-windows:before {
  content: "\e039";
}

.socicon-qq:before {
  content: "\e03a";
}

.socicon-douban:before {
  content: "\e03b";
}

.socicon-meetup:before {
  content: "\e03c";
}

.socicon-playstation:before {
  content: "\e03d";
}

.socicon-android:before {
  content: "\e03e";
}

.socicon-snapchat:before {
  content: "\e03f";
}

.socicon-twitter:before {
  content: "\e040";
}

.socicon-facebook:before {
  content: "\e041";
}

.socicon-googleplus:before {
  content: "\e042";
}

.socicon-pinterest:before {
  content: "\e043";
}

.socicon-foursquare:before {
  content: "\e044";
}

.socicon-yahoo:before {
  content: "\e045";
}

.socicon-skype:before {
  content: "\e046";
}

.socicon-yelp:before {
  content: "\e047";
}

.socicon-feedburner:before {
  content: "\e048";
}

.socicon-linkedin:before {
  content: "\e049";
}

.socicon-viadeo:before {
  content: "\e04a";
}

.socicon-xing:before {
  content: "\e04b";
}

.socicon-myspace:before {
  content: "\e04c";
}

.socicon-soundcloud:before {
  content: "\e04d";
}

.socicon-spotify:before {
  content: "\e04e";
}

.socicon-grooveshark:before {
  content: "\e04f";
}

.socicon-lastfm:before {
  content: "\e050";
}

.socicon-youtube:before {
  content: "\e051";
}

.socicon-vimeo:before {
  content: "\e052";
}

.socicon-dailymotion:before {
  content: "\e053";
}

.socicon-vine:before {
  content: "\e054";
}

.socicon-flickr:before {
  content: "\e055";
}

.socicon-500px:before {
  content: "\e056";
}

.socicon-wordpress:before {
  content: "\e058";
}

.socicon-tumblr:before {
  content: "\e059";
}

.socicon-twitch:before {
  content: "\e05a";
}

.socicon-8tracks:before {
  content: "\e05b";
}

.socicon-amazon:before {
  content: "\e05c";
}

.socicon-icq:before {
  content: "\e05d";
}

.socicon-smugmug:before {
  content: "\e05e";
}

.socicon-ravelry:before {
  content: "\e05f";
}

.socicon-weibo:before {
  content: "\e060";
}

.socicon-baidu:before {
  content: "\e061";
}

.socicon-angellist:before {
  content: "\e062";
}

.socicon-ebay:before {
  content: "\e063";
}

.socicon-imdb:before {
  content: "\e064";
}

.socicon-stayfriends:before {
  content: "\e065";
}

.socicon-residentadvisor:before {
  content: "\e066";
}

.socicon-google:before {
  content: "\e067";
}

.socicon-yandex:before {
  content: "\e068";
}

.socicon-sharethis:before {
  content: "\e069";
}

.socicon-bandcamp:before {
  content: "\e06a";
}

.socicon-itunes:before {
  content: "\e06b";
}

.socicon-deezer:before {
  content: "\e06c";
}

.socicon-telegram:before {
  content: "\e06e";
}

.socicon-openid:before {
  content: "\e06f";
}

.socicon-amplement:before {
  content: "\e070";
}

.socicon-viber:before {
  content: "\e071";
}

.socicon-zomato:before {
  content: "\e072";
}

.socicon-quora:before {
  content: "\e073";
}

.socicon-draugiem:before {
  content: "\e074";
}

.socicon-endomodo:before {
  content: "\e075";
}

.socicon-filmweb:before {
  content: "\e076";
}

.socicon-stackexchange:before {
  content: "\e077";
}

.socicon-wykop:before {
  content: "\e078";
}

.socicon-teamspeak:before {
  content: "\e079";
}

.socicon-teamviewer:before {
  content: "\e07a";
}

.socicon-ventrilo:before {
  content: "\e07b";
}

.socicon-younow:before {
  content: "\e07c";
}

.socicon-raidcall:before {
  content: "\e07d";
}

.socicon-mumble:before {
  content: "\e07e";
}

.socicon-medium:before {
  content: "\e06d";
}

.socicon-bebee:before {
  content: "\e07f";
}

.socicon-hitbox:before {
  content: "\e080";
}

.socicon-reverbnation:before {
  content: "\e081";
}

.socicon-formulr:before {
  content: "\e082";
}

.socicon-instagram:before {
  content: "\e057";
}

.socicon-battlenet:before {
  content: "\e083";
}
