/* =Freelance Skin
-------------------------------------------------------------- */

/* Loader */

.queryloader__overlay__percentage {
  font-family: "Lato", sans-serif;
}

/* Common Typography */

body {
  font-family: "Lekton", serif;
}

.full-width {
  background: #000;
}

.full-width-black {
  background: #000;
}

h1,
h2,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
}

h1,
h2,
h4 {
  font-weight: 300;
}

h3 {
  font-family: "Lekton", serif;
}

/* Buttons */

button.button {
  color: #fff;
  background: transparent;
  padding: 15px 40px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  font-family: "Lato", sans-serif;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
}

button.button:hover {
  color: #232323;
  background: #fff;
  border: 2px solid #fff;
}

a.fixed-button,
a.fixed-button-back {
  color: #fff;
  font-family: "Lato", sans-serif;
}

/* Disabe Slider Effects (delete this if you need to re-enable the slider) */

#progress-line {
  display: none;
}

.bxslider li {
  opacity: 1 !important;
}

/* Subscribe Modal */

.md-content {
  background: url(../../images/freelance/modal-bg.jpg) no-repeat;
  background-size: cover;
}

form .text {
  font-family: "Lato", sans-serif;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

form .text:focus {
  border: 1px solid #fff;
}

form .submit {
  font-family: "Lato", sans-serif;
  color: #f2f2f2;
  background: #000;
  border: 2px solid #000;
}

form .submit:hover {
  background: #232323;
  border: 2px solid #232323;
}

/* Icon Boxes */

.icon-box .icon {
  color: #000;
}

/* Portfolio */

.thumbnails {
  margin: 0px 10px;
}

.thumbnail {
  width: 100%;
  margin-bottom: 10px;
}

@media only screen and (min-width: 480px) {
  .thumbnails {
    border-left: 5px solid #fff;
    border-right: 5px solid #fff;
    margin: 0;
  }
  .thumbnail {
    width: 50%;
    border-left: 5px solid #fff;
    border-right: 5px solid #fff;
    border-bottom: 10px solid #fff;
    margin-bottom: 0px;
  }
}

/* Lightbox */

.pswp__counter {
  font-family: "Lato", sans-serif;
  font-size: 10px;
}

.pswp__share-tooltip {
  font-family: "Lato", sans-serif;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

/* Contact */

fieldset label {
  font-family: "Lato", sans-serif;
}

fieldset input,
fieldset textarea {
  padding: 14px 7px 14px 15px;
  margin-bottom: 40px;
  border: 2px solid #f2f2f2;
  background: #f2f2f2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

fieldset input:focus,
fieldset textarea:focus {
  background: #fff;
}

#submit {
  font-family: "Lato", sans-serif;
  padding: 15px 50px;
  color: #fff;
  background: #000;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  -webkit-box-shadow: 0px 6px 10px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 6px 10px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 10px 3px rgba(0, 0, 0, 0.1);
}

#submit:hover {
  background: #0634ff;
}
