body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
.pswp img {
  width: 65% !important;
  height: 66vh !important;
  top: 41px !important;
  left: unset !important;
}
.pswp__zoom-wrap {
  width: 100% !important;
  height: 100% !important;
  justify-content: center;
  align-items: center;
  display: flex;
}
.pswp__custom-caption {
  font-size: 16px;
  color: #fff;
  width: calc(100% - 32px);
  max-width: 400px;
  padding: 2px 8px;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
}
.pswp__custom-caption h5 {
  color: #fff !important;
}
.pswp__custom-caption a {
  color: #fff;
  text-decoration: underline;
}
.hidden-caption-content {
  display: none;
}
.pswp__bg {
  opacity: 1 !important;
}
